
import React from 'react';
import Routers from 'Routers';

import { ThemeProvider, createTheme } from '@mui/material/styles';
import { CssBaseline, useMediaQuery } from '@mui/material';


export const ColorModeContext = React.createContext({
  ThemedApp: () => { },
  getInputColor: () => { },
  getMapsInputColor: () => { },
  getColorMode: () => { }
});

function App()
{
  return (
    <Routers />
  );
}

export default function ThemedApp()
{
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const [mode, setMode] = React.useState(
    localStorage.getItem('colorMode') || (prefersDarkMode ? 'light' : 'light')
  );

  const setColorMode = (newMode) =>
  {
    setMode(newMode);
    localStorage.setItem('colorMode', newMode);
  };

  const colorMode = React.useMemo(
    () => ({
      ThemedApp: () =>
      {
        setColorMode(mode === 'light' ? 'dark' : 'light');
      },
      getInputColor: () =>
      {
        return mode === 'light' ? '#ffffff' : 'transparent';
      },
      getMapsInputColor: () =>
      {
        return mode === 'light' ? '#ffffff' : '#222222';
      },
      getColorMode: () =>
      {
        return mode
      }
    }),
    [mode]
  );

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode,
          background: {
            default: mode === 'light' ? '#fafafa' : '#000000',
            paper: mode === 'light' ? '#ffffff' : '#111111',
          },
          primary: {
            main: '#156691',
            light: '#4DA5E8',
            dark: '#0F4D7D',
          },
          secondary: {
            main: mode === 'light' ? '#ededed' : '#333333',
            light: '#ededed',
            dark: '#ededed',
          },
          error: {
            main: '#b7363e',
            light: '#e06469',
            dark: '#831c24',
          },
          warning: {
            main: '#c47e2f',
            light: '#edaf6a',
            dark: '#914d14',
          },
          info: {
            main: '#3f8d7f',
            light: '#72bca6',
            dark: '#0e574e',
          },
          success: {
            main: '#4c8d39',
            light: '#7abb6a',
            dark: '#2b5e20',
          },
          lightGreen: {
            main: '#a1d99b',
            light: '#a1d99b',
            dark: '#a1d99b',
          },
          logoSecondary: {
            main: '#f4883d',
            light: '#f4883d',
            dark: '#f4883d',
            contrastText: '#ffffff'
          },
        },
      }),
    [mode],
  );

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <App />
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}