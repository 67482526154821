import Loading from "Components/Utility/Loading/Loading";

import React, { Suspense, lazy, } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

const LandingPage = lazy(() => import('Pages/LandingPage/LandingPage'));
const TermsAndConditions = lazy(() => import('Pages/TermsAndConditions/TermsAndConditions'));
const PrivacyPolicy = lazy(() => import('Pages/PrivacyPolicy/PrivacyPolicy'));
const NotFound = lazy(() => import('Components/Utility/NotFound/NotFound'));

const Products = lazy(() => import('Pages/Products/Products'));
const Boxes = lazy(() => import('Pages/Boxes/Boxes'));
const Motors = lazy(() => import('Pages/Motors/Motors'));
const Shutters = lazy(() => import('Pages/Shutters/Shutters'));
const ProductInfo = lazy(() => import('Pages/ProductInfo/ProductInfo'));

const CustomerService = lazy(() => import("Pages/CustomerService/CustomerService"));
const Maintenance = lazy(() => import("Pages/Maintenance/Maintenance"));
const Quality = lazy(() => import("Pages/Quality/Quality"));
const Warranty = lazy(() => import("Pages/Warranty/Warranty"));
const Clients = lazy(() => import("Pages/Clients/Clients"));
const ClientInfo = lazy(() => import('Pages/ClientInfo/ClientInfo'));
const WhyEvoShutter = lazy(() => import("Pages/WhyEvoShutter/WhyEvoShutter"));
const ContactUs = lazy(() => import("Pages/ContactUs/ContactUs"));
const OurVision = lazy(() => import("Pages/OurVision/OurVision"));
const Resources = lazy(() => import("Pages/Resources/Resources"));
const Partners = lazy(() => import("Pages/Partners/Partners"));
const PartnerInfo = lazy(() => import('Pages/PartnerInfo/PartnerInfo'));
const Events = lazy(() => import('Pages/Events/Events'));

export default function Routers()
{
  return (
    <BrowserRouter>
      <Suspense fallback={<Loading />}>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/products" element={<Products />} />
          <Route path="/products/:productID" element={<ProductInfo />} />
          <Route path="/products/shutters" element={<Shutters />} />
          <Route path="/products/boxes" element={<Boxes />} />
          <Route path="/products/motors" element={<Motors />} />
          <Route path="/services/quality" element={<Quality />} />
          <Route path="/services/warranty" element={<Warranty />} />
          <Route path="/services/maintenance" element={<Maintenance />} />
          <Route path="/services/customer-service" element={<CustomerService />} />
          <Route path="/our-clients" element={<Clients />} />
          <Route path="/our-clients/:clientID" element={<ClientInfo />} />
          <Route path="/our-partners" element={<Partners />} />
          <Route path="/our-partners/:partnerID" element={<PartnerInfo />} />
          <Route path="/about-us/why-evo-shutter" element={<WhyEvoShutter />} />
          <Route path="/about-us/our-vision" element={<OurVision />} />
          <Route path="/about-us/resources" element={<Resources />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/events" element={<Events />} />
          <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  )
}
