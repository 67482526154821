import './index.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import ReactDOM from 'react-dom/client';
import App from './App';
import Loading from 'Components/Utility/Loading/Loading';
import React, { Suspense } from 'react';

if (process.env.NODE_ENV === 'production')
{
  console.log = function () { };
}

const load = <Loading />;
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Suspense fallback={load}>
    <App />
  </Suspense>,
);
